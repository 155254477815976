<template>
	<div class="row other-products" v-if="products">
		<div v-for="product in products" :key="product.slug" class="col-md-6 col-lg-4 product"
			:class="{ 'coming-soon': product.coming_soon, 'sold-out': product.sold_out }">
			<router-link :to="{ name: 'Product', params: { slug: product.slug } }" class="image-link">
				<img :src="product.thumbnail" :alt="product.name" loading="lazy" :class="{ wide: product.name.includes('+'), /*tiny: 'use tiny class if the product has only small bottles'*/ }">
			</router-link>
			<h3 class="italic font-brown">
				{{ product.name }}
			</h3>
			<p>
				{{ product.description }}
			</p>
      <div v-if="product.implicit_discount && implicitDiscount(product)" class="discount-badge">
        <span>
          -{{ implicitDiscount(product) }} %
        </span>
      </div>
      <FlameScale v-if="product.flame_scale || !syrupExclude || !syrup" :flames="product.flame_scale" />
      <div v-else class="mt-4"></div>

			<router-link :to="{ name: 'Product', params: { slug: product.slug } }">
				<Button color="white">
					Detail produktu
				</Button>
			</router-link>
		</div>
	</div>
</template>

<script>
	import Button from "./Button";
  import FlameScale from '@/views/components/FlameScale.vue';

	export default {
		name: "OtherProducts",
		props: {
		  exclude: String,
      count: {
		    type: Number,
        default: 3
      },
      syrup: {
        type: Boolean,
        default: null,
      },
      syrupExclude: { // true = exclude non syrup-equivalent products; false = just show them later
        type: Boolean,
        default: true,
      },
      preferentialSorting: {  // true = disregard products position attribute and show similar products first
        type: Boolean,
        default: false,
      }
    },
		components: { FlameScale, Button},
		computed: {
			products() {
				let allProducts = this.$api.shortProducts;

				if (!allProducts) return null;

        return Object.values(allProducts)
            .sort(this.sortProducts)
            .filter((product) => product.slug !== this.exclude && (this.syrup === null || !this.syrupExclude || this.syrup === product.is_syrup))
            .slice(0, this.count);
			}
		},
    methods: {
      implicitDiscount(product) {
        return this.$api.productSizes.map((size) => product.implicit_discount[size]).find((x) => x);
      },
      sortProducts(a, b) {
        if (!this.preferentialSorting) {
          return a.position - b.position;
        }

        // Show coming soon products last
        if (a.coming_soon && !b.coming_soon)
          return 1;

        if (!a.coming_soon && b.coming_soon)
          return -1;

        // Show sold-out products second-to-last
        if (a.sold_out && !b.sold_out)
          return 1;

        if (!a.sold_out && b.sold_out)
          return -1;

        // Show syrup equivalent products first
        if (this.syrup !== null) {
          if (a.is_syrup !== this.syrup && b.is_syrup === this.syrup)
            return 1;

          if (a.is_syrup === this.syrup && b.is_syrup !== this.syrup)
            return -1;
        }

        return 0
      }
    }
	}
</script>