import Vue from "vue";
import App from "./views/Root.vue";
import router from "./router";
import api from "./api";
import Meta from "vue-meta";
import LocalStorage from "./local-storage";

import "./cookies";

// Bugsnag error monitoring
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
if (process.env.VUE_APP_BUGSNAG_KEY) {
	Bugsnag.start({
		apiKey: process.env.VUE_APP_BUGSNAG_KEY,
		plugins: [new BugsnagPluginVue()],
		releaseStage: process.env.VUE_APP_ENV,
	});

	Bugsnag.getPlugin('vue')
		.installVueErrorHandler(Vue);
}

// Hotjar user tracking
/*import Hotjar from 'vue-hotjar'

if (process.env.VUE_APP_HOTJAR_ID)
	Vue.use(Hotjar, {
		id: process.env.VUE_APP_HOTJAR_ID,
		isProduction: process.env.VUE_APP_MODE === "production"
	});*/

let gibonValues = LocalStorage.get("gibonValidFields");
if (!gibonValues)
	gibonValues = {
		zip: null,
		town: null
	};

Vue.prototype.$api = Vue.observable(api);
Vue.prototype.$storage = Vue.observable({
	cart: {
		shipping: {
			type: null,
			price: 0,
			required: true,
			pickupPoint: null
		},
		payment: {
			type: null,
			required: true,
			price: 0
		},
		subscriptionWeek: null, // duration (active or not) is stored in a session
		subscriptionAsap: null,
	},
	checkout: {
		contact: {
			email: null,
			phone: null,
		},
		shipping: {
			name: null,
			address: null,
			town: gibonValues.town,
			zip: gibonValues.zip,
			country: null,
		},
		billing: {
			company: null,
			name: null,
			address: null,
			town: gibonValues.town,
			zip: gibonValues.zip,
			country: null,
			cin: null,
			vatin: null,
		},
	},
	order: null,
	gibonValidZip: gibonValues.zip,	// Zip code that has been verified through Gibon API
	theme: "white",
});

// Source: https://stackoverflow.com/a/55686711
Vue.prototype.$smoothScroll = (topOffset, doneCallback = () => {}) => {
	if (typeof topOffset === "object")
		topOffset = topOffset.offsetTop;

	const fixedOffset = topOffset.toFixed(),
		onScroll = function () {
			if (window.pageYOffset.toFixed() === fixedOffset) {
				window.removeEventListener('scroll', onScroll);
				doneCallback();
			}
		};

	window.addEventListener('scroll', onScroll, { passive: true });
	onScroll();
	window.scrollTo({
		top: topOffset,
		left: 0,
		behavior: 'smooth'
	})
};

import vuePositionSticky from 'vue-position-sticky'
Vue.use(vuePositionSticky);

Vue.use(Meta);

import "./assets/sass/main.sass";

Vue.config.productionTip = false;

const PRICE_DECIMAL_PLACES = 0;
Vue.prototype.$price = (raw, maxDecimals = PRICE_DECIMAL_PLACES, minDecimals = 0) => {
	if (typeof Intl === 'undefined')
		return raw + ' CZK';

	return new Intl.NumberFormat('cs-CZ', {
		style: 'currency', currency: 'CZK',
		minimumFractionDigits: minDecimals,
		maximumFractionDigits: maxDecimals,
	}).format(raw);
};

new Vue({
	router,
	render: h => h(App)
}).$mount("#app");
