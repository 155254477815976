<template>
	<div class="product-price">
    <div v-if="product.implicit_discount && product.implicit_discount[size]" class="discount-badge">
          <span>
            -{{ product.implicit_discount[size] }} %
          </span>
    </div>
		<strong class="price">
      {{ price }}
      <span v-if="priceDetails" v-html="priceDetails" class="price-details font-color"></span>
    </strong>
    <strong class="price price-sub" :class="{ empty: !priceSubRaw || product.forbid_subscription }" v-if="$api.cart.subscription.active">
      <span>
        <span class="side-text font-color">až</span>
        {{ priceSub }}
      </span>
      <span class="side-text font-color">
        v rámci předplatného

        <HelperTooltip color="white">
          Po aktivaci předplatného Vám každý měsíc automaticky strhneme platbu a odešleme objednávku.
          Zrušit můžete kdykoliv.
          Více informací naleznete v košíku.
        </HelperTooltip>
      </span>
    </strong>
		<ul class="size-picker" :class="size" v-if="sizes.length > 1 && pickSize">
			<li @click="changeSize(sizeOption)" :class="{ active: size === sizeOption }" v-for="sizeOption in sizes" :key="sizeOption">
        {{ product[`size_${sizeOption}`] }}
      </li>
		</ul>
    <b class="bottles-in-package" v-if="product.bottles_in_package > 1 && pickSize">
      {{ product.bottles_in_package }} x {{ product[`size_${size}`] }}
    </b>
    <strong class="font-brown tick-before" v-if="product.name === 'Starter pack'">
      Doprava zdarma
    </strong>
    <BuyButton :class="!allowBuy ? 'hidden' : ''" :size="size" :color="color" :product="product" :quantity="quantity" @changeQuantity="changeQuantity" />
	</div>
</template>

<script>
	import BuyButton from "../../components/BuyButton";
  import HelperTooltip from '@/views/components/HelperTooltip.vue';
  export default {
		name: "Price",
    components: { HelperTooltip, BuyButton},
    props: {
      product: {
        type: Object,
        required: true,
      },
      allowBuy: {
        type: Boolean,
        required: false,
        default: true,
      },
      pickSize: {
        type: Boolean,
        default: true,
      },
      defaultSize: {
        type: String,
        default: "",
      },
      color: {
        type: String,
        required: false,
        default: "black",
      },
    },
		data() {
			return {
				size: this.defaultSize !== "" ? this.defaultSize : this.sizes[0],
				quantity: 1,
			};
		},
		computed: {
			priceRaw() {
				return this.product[`price_${this.size}_raw`];
			},
			price() {
				return this.$price(this.priceRaw * this.quantity);
			},
      priceSubRaw() {
        return Math.min(...Object.values(this.product[`price_${this.size}_subscription_raw`]));
      },
      priceSub() {
        return this.$price(this.priceSubRaw);
      },
      priceDetails() {
			  return this.product[`price_details_${this.size}`];
      },
      sizes() {
        return this.$api.productSizes.filter((size) => this.product[`price_${size}_raw`] !== 0);
      }
		},
		methods: {
			changeSize(size) {
				this.size = size;
				this.quantity = 1;
				this.$emit("changeSize", this.size);
			},
      changeQuantity(quantity) {
			  this.quantity = quantity;
      }
		}
	}
</script>
