<template>
  <div class="flame-scale">
    <template v-if="flames">
      <span class="flame-scale-label">Pálivost</span>

      <!-- Full flame -->
      <Icon v-for="index in Math.floor(flames)" src="flame"
            :key="'flame-scale-'+index"/>

      <!-- Half flame -->
      <Icon v-if="flames % 1" src="flame-half"/>

      <!-- Empty flame -->
      <Icon v-for="index in (4-Math.ceil(flames))" src="flame"
            class="flame-empty"
            :key="'flame-scale-empty-'+index"/>
    </template>
  </div>
</template>

<script>
import Icon from '@/views/components/Icon.vue';
export default {
  name: "FlameScale",
  components: { Icon},
  props: [
    "flames",
  ]
}
</script>
